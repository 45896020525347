<template>
  <div id="app">
    <router-link to="/home" ></router-link>
    <router-view></router-view>
  </div>
</template>
 
<script>
export default {
  name: 'App'
}
</script>